import { Component, OnInit, HostListener } from '@angular/core';
import { AccountingRepositoryService } from "app/services/accountingrepository.service";
import { Router, ActivatedRoute } from "@angular/router";



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  host: { '(document:keydown)': 'handleKeyboardEvent($event)' }
})
export class LoginComponent implements OnInit {

  private userName: string;
  private password: string;
  returnUrl: string;
  constructor(private _accountingRepository: AccountingRepositoryService, private router: Router, private route: ActivatedRoute) { 

  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || '/';
   
  }

  ngAfterViewInit() {
    if (this._accountingRepository.checkForSavedUser() == true)
    {
       this.router.navigateByUrl(this.returnUrl);
    }
  }

  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == "Enter")
    {
      this.login();
    }
  }

  login() {

   this._accountingRepository.login(this.userName, this.password).then(result => {
            if (result == true)
            {
              
              //this.router.navigate(['/']);
              this.router.navigateByUrl(this.returnUrl);

            }
            else
            {
              alert("Bad Username or Password Please Try Again");
            }
        }, error => {
          console.log(error.message)
        });
  }


}
