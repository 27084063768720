import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccountingRepositoryService } from 'app/services/accountingrepository.service';
import { Employee } from 'app/model/entity-model';

@Injectable()
export class AuthGuard implements CanActivate {
 
    constructor(private router: Router, private _accountingRepositoryService : AccountingRepositoryService ) { }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (AccountingRepositoryService.Authenticated == true ) {
            // logged in so return true
            return true;
        }
 
        // not logged in so redirect to login page with the return url and return false
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}
