import { EntityBase } from './entity-base';
import { Employee } from './employee';
import { ExpenseDetail } from './expense-detail';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ExpenseHeader extends EntityBase {

    /// <code> Place custom code between <code> tags
    /// [Initializer]
    static initializer(entity: ExpenseHeader) { }
    /// </code>

    // Generated code. Do not place code below this line.
    id: number;
    amexReport: boolean;
    amexImportId: number;
    locked: boolean;
    readyForClassification: boolean;
    classificationComplete: boolean;
    readyForEmployeeReview: boolean;
    employeeReviewComplete: boolean;
    readyForApproval: boolean;
    approved: boolean;
    createdDate: Date;
    createdById: number;
    lastModifiedDate: Date;
    lastModifiedById: number;
    submittedDate: Date;
    submittedById: number;
    approvedDate: Date;
    approvedById: number;
    expenseTotal: number;
    approvedTotal: number;
    rejectedTotal: number;
    fraudTotal: number;
    description: string;
    employee: Employee;
    expenseDetails: ExpenseDetail[];
}

