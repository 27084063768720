import { Component, OnInit } from '@angular/core';
import { AmexImport } from 'app/model/entity-model';
import { AccountingRepositoryService } from 'app/services/accountingrepository.service';


@Component({
  selector: 'app-amex-statement-list',
  templateUrl: './amex-statement-list.component.html',
  styleUrls: ['./amex-statement-list.component.css']
})
export class AmexStatementListComponent implements OnInit {
  private ameximports: AmexImport[];
  private errorMessage: string;
  constructor(private _accountingRepository: AccountingRepositoryService) { }

      ngOnInit() {
          this._accountingRepository.getAmexImports().then(ameximports => {
          this.ameximports = ameximports;
      },error => { 
        console.log(error.message);})
      }


   

    LockReport(AmexImportId: number)
    {
      if (confirm("Are you sure you want to permanently lock this report")) {
          this._accountingRepository.lockAMEXExpenseReport(AmexImportId);
      }
    }


}

