import { InitialNavigation } from '@angular/router/src/router_module';
import { Injectable } from '@angular/core';
import { config, EntityManager, EntityQuery, Entity, Predicate, Validator, FilterQueryOp, FetchStrategy, FetchStrategySymbol, EntityState, core} from 'breeze-client';
import { ExpenseHeader, ExpenseDetail, Employee,ExpenseReason, ExpenseGLClassification, ExpenseProject, Prospect, AmexImport } from '../model/entity-model';
import { RegistrationHelper } from '../model/registration-helper';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from "rxjs/Observable";
import { ReportLink } from "app/model/report-link";

@Injectable()
export class AccountingRepositoryService {

//private _em: EntityManager = new EntityManager("http://localhost:61000/breeze/accounting");
private _em: EntityManager = new EntityManager("https://portal.wtsonline.com/breeze/accounting");
private _expenseHeaderCached: boolean;
private _employeesCached: boolean;
private savePassword: boolean;
private MetaDataImported: boolean = false;

private _currentUser : BehaviorSubject<Employee> = new BehaviorSubject<Employee>(new Employee());
// private _availableReports : BehaviorSubject<ReportLink[]> = new BehaviorSubject<ReportLink[]>();
public readonly CurrentUser: Observable<Employee> = this._currentUser.asObservable();
public LookupExpenseGLBusinessSegments: string[];
public LookupExpenseGLDescription : string[];
public LookupAccpacCustomers : any;
public LookupExpenseReasons : ExpenseReason[];
public LookupExpenseProjects: ExpenseProject[];
public static AvailableReports : ReportLink[] = [];
public static Authenticated : boolean;
public static CurrentToken: string;

constructor() {
  this._currentUser.next(new Employee());
  AccountingRepositoryService.Authenticated = false;
   //let NewReport = new ReportLink();
  // NewReport.Link = "https://portal.wtsonline.com/breeze/reporting/downloadreport?AmexImportId=16";
  // NewReport.Name = "Test Report";
   //this.AvailableReports.push(NewReport);
  //AccountingRepositoryService.AvailableReports.push({ Name: 'Monthly AMEX Report', Link: 'https://portal.wtsonline.com/breeze/reporting/downloadreport?AmexImportId=16'});
  //this.CurrentUser = new Employee();
}

private _initialized: boolean;
initialize() {   
    let promise = new Promise<boolean>((resolve, reject) => {
        if (this._initialized){
          resolve(true);
        } 
        else {
           
            //Lookup will pull metadata automatically
            this._em.executeQuery(EntityQuery.from('Lookups')).then(lookupsResponse => {
                this.LookupExpenseGLBusinessSegments = lookupsResponse.results[0]["expenseGLBusinessSegments"];
                this.LookupExpenseGLDescription = lookupsResponse.results[0]["expenseGLDescriptions"];                      
                this.LookupExpenseProjects = lookupsResponse.results[0]["expenseProjects"];
                this.LookupExpenseReasons = lookupsResponse.results[0]["expenseReasons"];
                this.MetaDataImported = true;
                this._initialized = true;
                resolve(true);
            }, error => console.error(error));
        }
    });
    return promise;
} // initialize

retrieveMetaData(): Promise<boolean> {
 
    let promise = new Promise<boolean>((resolve, reject) => { 
       if(this._em.metadataStore == null)
       {
          this._em.fetchMetadata().then(_ => {
            resolve(true);            
          }, error => {
            console.error(error);
            resolve(false);
          })
       }
       else
       {
         resolve(true); // we already have it
       }
    });
    return promise;
}

getExpenseGLBusinessSegments(): string[] {
  return <any>this._em.executeQueryLocally(EntityQuery.from("ExpenseGLBusinessSegments").toType("gLBusinessDescriptionProp")) as string[];
}
 getAmexImports(): Promise<AmexImport[]> {
    let promise = new Promise<AmexImport[]>((resolve, reject) => {

      let query = EntityQuery.from("AmexImports").where("importFinished", "!=", null).orderByDesc('notes');            
      this._em.executeQuery(query).then(response => {
        resolve(response.results as AmexImport[]);
      }, error => reject(error));
    });
    return promise;
  }  //getAmexImports

 getEmployees(): Promise<Employee[]> {
    let promise = new Promise<Employee[]>((resolve, reject) => {
      //let query = EntityQuery.from("Employees").withParameters({".ASPXAUTH" : "C6AF43CD5513EE4086CE3F08A3AC44C4BCFB2F18B1A86224B8500F3BE8D6A46374BB471BC771B12A1D235D3C028BC549913DBD642A1356E55F83FAE659BD604F2E643C6590604944060F613E2573D136BAE958B455F7F2FF04634C66CDF73945"}).orderBy('lastName, firstName');            
      let query = EntityQuery.from("Employees").orderBy('lastName, firstName');            
      this._em.executeQuery(query).then(response => {
        resolve(response.results as Employee[]);
      }, error => reject(error));
    });
    return promise;
  }  //getEmployees

sendAmexReminderEmails(AmexImportId: number, subject: string, message: string): Promise<number> {
  let promise = new Promise<number>((resolve, reject) => {
    let query = EntityQuery.from("SendReminderEmails").withParameters({AmexImportId: AmexImportId, Subject: subject, Message: message});
    this._em.executeQuery(query).then(response => {          
          console.log(response.results[0]);
          resolve(1);
       }, error => reject(error));
  });
  return promise;
  
 }


submitEmployeeExpenseReport(ExpenseHeaderId : number)
{
  let promise = new Promise<number>((resolve, reject) => {
    let query = EntityQuery.from("SubmitEmployeeExpenseReport").withParameters({ExpenseHeaderId: ExpenseHeaderId});
    this._em.executeQuery(query).then(response => {          
          console.log(response.results[0]);
          resolve(1);
       }, error => reject(error));
  });
  return promise;
}

lockAMEXExpenseReport(AMEXImportId : number)
{
  let promise = new Promise<number>((resolve, reject) => {
    let query = EntityQuery.from("LockAMEXExpenseReport").withParameters({AMEXImportId: AMEXImportId});
    this._em.executeQuery(query).then(response => {          
          console.log(response.results[0]);
          resolve(1);
       }, error => reject(error));
  });
  return promise;
}
login( userName: string, password: string): Promise<boolean> {
   let RequestajaxAdapter = <any>config.getAdapterInstance('ajax');
   let Requestheaders = RequestajaxAdapter.defaultSettings['headers'] || {  };
   
   this.savePassword = false;
   if (userName.endsWith('save!'))
   {
     this.savePassword = true;
     userName = userName.substring(0,userName.length - 5);
   }
   Requestheaders['UserName'] = userName;
   Requestheaders['Password'] = password;
   RequestajaxAdapter.defaultSettings['headers'] = Requestheaders;
    let promise = new Promise<boolean>((resolve, reject) => {
      let query = EntityQuery.from("Login");            
      this._em.executeQuery(query).then(response => {
        if (response.results != null)
        {

          if (response.results.length == 1)
          {
              this._currentUser.next(response.results[0]["employee"]);
              // update all future calls to pass the token
              let ajaxAdapter = <any>config.getAdapterInstance('ajax');
              let headers = ajaxAdapter.defaultSettings['headers'] || {  };
              delete headers['UserName'];
              delete headers['Password'];
              if(this.savePassword == true)
              {
                localStorage.setItem('SavedToken',response.results[0]["token"]);
                localStorage.setItem('SavedUser',this._em.exportEntities([response.results[0]["employee"]], false));
              }
              headers['Token'] = response.results[0]["token"];
              AccountingRepositoryService.CurrentToken = response.results[0]["token"];
              ajaxAdapter.defaultSettings['headers'] = headers;
              AccountingRepositoryService.Authenticated = true;
              resolve(true);
          }
          else
          {
            AccountingRepositoryService.Authenticated = false;
            resolve(false);
            
          }
        }
        else
        {          
          AccountingRepositoryService.Authenticated = false;
          resolve(false);
        }
      }, error => reject(error));
    });
    return promise;
}

logout(){

   let promise = new Promise<boolean>((resolve, reject) => {
      let query = EntityQuery.from("Logout");            
      this._em.executeQuery(query).then(response => {
        let ajaxAdapter = <any>config.getAdapterInstance('ajax');
        let headers = ajaxAdapter.defaultSettings['headers'] || {  };
        delete headers['Token'];
        ajaxAdapter.defaultSettings['headers'] = headers;
        AccountingRepositoryService.Authenticated = false;
        localStorage.removeItem("SavedToken");
        localStorage.removeItem("SavedUser");        
        this._currentUser.next(new Employee());
        resolve(true);
      }, error => reject(error));
    });
    return promise;
}

checkForSavedUser():boolean
{
  // console.log("Going to check for savedtoken");
  //  if (localStorage.getItem("SavedToken") != null)
  //  {
  //           console.log("Saved token is not null")
  //             let ajaxAdapter = <any>config.getAdapterInstance('ajax');
  //             let headers = ajaxAdapter.defaultSettings['headers'] || {  };
  //             delete headers['UserName'];
  //             delete headers['Password'];
  //             headers['Token'] = localStorage.getItem("SavedToken");
  //             ajaxAdapter.defaultSettings['headers'] = headers;
  //             var SavedEmployee = localStorage.getItem("SavedUser");
  //             var ImportedSavedEmployee = this._em.importEntities(SavedEmployee);
  //             this._currentUser.next(ImportedSavedEmployee.entities[0] as Employee);
  //             AccountingRepositoryService.Authenticated = true;
  //           return true;
  //  }
  //  else
   {
            return false;
   }

}

 getEmployee(employeeId: number): Promise<Employee> {
   // TODO: Figure out what to do if bad ID is passed in and no record returned
    let promise = new Promise<Employee>((resolve, reject) => {
      let query = EntityQuery.from("Employees").where("id","equals",employeeId);            
      this._em.executeQuery(query).then(response => {
        resolve(response.results[0] as Employee);
      }, error => reject(error));
    });
    return promise;
  }  //getEmployees

getProspectByKeyword(keyword: string): Promise<Prospect[]> {   
    let promise = new Promise<Prospect[]>((resolve, reject) => {
      let predicate = Predicate;
      let p1 = Predicate.create("prospectName","contains", keyword);
      let p2 = Predicate.create("address","contains", keyword);
      let p3 = Predicate.create("city","contains", keyword);
      let p4 = Predicate.create("state","contains", keyword);
      let p5 = Predicate.create("zipcode","contains", keyword);
      let p6 = Predicate.create("siteContact","contains", keyword);
      let whereClause = p1.or(p2.or(p3.or(p4.or(p5.or(p6)))));
      let query = EntityQuery.from("Prospects").where(whereClause);            
      this._em.executeQuery(query).then(response => {
        resolve(response.results as Prospect[]);
      }, error => reject(error));
    });
    return promise;
  }  //ProspectByKeyword

createEntity(typeName: string): Entity {
  let options: any = {};
  return this._em.createEntity(typeName, options);
}

saveNewProspect(NewProspect: Prospect): Promise<void> {
  let promise = new Promise<void>((resolve, reject) => {
        let prospect: Array<any> = [NewProspect];
        this._em.saveChanges(prospect).then(_ => resolve(), error => console.error(error));
  });
  return promise;
}
getExpenseHeaderWithEmployeeAndExpenseDetails(expenseHeaderId: number): Promise<ExpenseHeader> {
    let promise = new Promise<ExpenseHeader>((resolve, reject) => {
      //let query = EntityQuery.from("ExpenseHeaders").where('id','equals',expenseHeaderId).expand("employee,expenseDetails").orderBy('expenseDetails.transactionDate');
      let query = EntityQuery.from("ExpenseHeaders").where('id','equals',expenseHeaderId).expand("employee");
      this._em.executeQuery(query).then(response => {
        resolve(response.results[0] as ExpenseHeader);
      }, error => reject(error));
    });
    return promise;
  } //getExpenseHeaderExpanded


  getExpenseHeadersForEmployeeByAmexId(amexImportId: number): Promise<ExpenseHeader[]> {
    let promise = new Promise<ExpenseHeader[]>((resolve, reject) => {
      //let query = EntityQuery.from("ExpenseHeaders").where('id','equals',expenseHeaderId).expand("employee,expenseDetails").orderBy('expenseDetails.transactionDate');
      let query = EntityQuery.from("ExpenseHeadersSortedByEmployeeLastName").where('amexImportId','equals',amexImportId).expand("employee");
      this._em.executeQuery(query).then(response => {
        resolve(response.results as ExpenseHeader[]);
      }, error => reject(error));
    });
    return promise;
  } //getExpenseHeaderExpanded

getExpenseDetails(expenseHeaderId: number): Promise<ExpenseDetail[]> {
    let promise = new Promise<ExpenseDetail[]>((resolve, reject) => {
      //let query = EntityQuery.from("ExpenseHeaders").where('id','equals',expenseHeaderId).expand("employee,expenseDetails").orderBy('expenseDetails.transactionDate');
      let query = EntityQuery.from("ExpenseDetails").where('expenseHeaderId','equals',expenseHeaderId).orderBy('transactionDate');
      this._em.executeQuery(query).then(response => {
        resolve(response.results as ExpenseDetail[]);
      }, error => reject(error));
    });
    return promise;
  } //getExpenseDetails

  getExpenseHeadersForEmployee(employeeId: number): Promise<ExpenseHeader[]> {
    let promise = new Promise<ExpenseHeader[]>((resolve, reject) => {
      let query = EntityQuery.from("ExpenseHeaders").where('employee.id','equals',employeeId).orderByDesc('description');
      this._em.executeQuery(query).then(response => {        
        resolve(response.results as ExpenseHeader[]);
      }, error => reject(error));
    });
    return promise;
  } //getExpenseHeaderForEmployee

  getAccpacCustomers(keyword: string): Promise<any> {
    let promise = new Promise<boolean>((resolve, reject) => {
      this._em.executeQuery(EntityQuery.from('AccpacCustomers').withParameters({ Keyword: keyword})).then(response => {
      resolve(<any>response.results);
      }, error => reject(error));
    });
    return promise;

  }
getAccpacCustomerOrders(customercode: string): Promise<any> {
    let promise = new Promise<boolean>((resolve, reject) => {
      this._em.executeQuery(EntityQuery.from('AccpacCustomerOrders').withParameters({ CustomerCode: customercode})).then(response => {
      resolve(<any>response.results);
      }, error => reject(error));
    });
    return promise;

  }
  saveChanges(): Promise<void> {
    let promise = new Promise<void>((resolve, reject) => {
      this._em.saveChanges().then(() => resolve(),
      error => reject(error));
    });
    return promise;
  }

  hasChanges() :  boolean {
    if(this._em.hasChanges()) {
     return true; 
    }
    else {
      return false;
    }
  }

  cancelChanges() : boolean {
    this._em.rejectChanges();
    return true;
  }
} // class AccountingRepositoryService

