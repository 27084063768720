import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InitGuard } from './services/init-guard';
import { AuthGuard } from './services/auth-guard';
import {EmployeeListComponent} from './employee-list/employee-list.component';
import {EmployeeExpenseReportListComponent} from './employee-expense-report-list/employee-expense-report-list.component';
import { ExpenseReportComponent} from './expense-report/expense-report.component';
import { LoginComponent } from "app/login/login.component";
import { CanDeactivateGuard } from "app/services/can-deactivate-guard";
import { AmexStatementListComponent } from './amex-statement-list/amex-statement-list.component';
import { AmexStatementEmployeeListComponent } from './amex-statement-employee-list/amex-statement-employee-list.component';
import { AmexSendEmailComponent } from './amex-send-email/amex-send-email.component';

//import { CheckForMetadataGuard } from "app/services/check-for-metadata-guard";


const routes: Routes = [
  {
    path: '', canActivate:[InitGuard], canActivateChild: [InitGuard], children: [
      {path: 'login', component: LoginComponent},
      {path: 'employees', component: EmployeeListComponent, canActivate: [AuthGuard]},
      {path: 'employeeexpensereportlist/:employeeId', component: EmployeeExpenseReportListComponent, canActivate: [AuthGuard]},
      {path: 'expensereport/:expenseHeaderId', component: ExpenseReportComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
      {path: 'amexstatementlist', component: AmexStatementListComponent, canActivate: [AuthGuard]},
      {path: 'amexstatementemployeelist/:amexImportId', component: AmexStatementEmployeeListComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
      {path: 'amexsendemail/:amexImportId', component: AmexSendEmailComponent, canActivate: [AuthGuard]}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
