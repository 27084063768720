import { Component, OnInit } from '@angular/core';
import { AccountingRepositoryService } from "app/services/accountingrepository.service";
import { Employee } from 'app/model/entity-model';
import { Observable } from "rxjs/Observable";
import { config } from "breeze-client";
import { ReportLink } from "app/model/report-link";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'WTS Expense App';
  private CurrentUser : Employee;
  private  ObservableCurrentUser : Observable<Employee>;
  private AvailableReports : ReportLink[];

 constructor(private _accountingRepository: AccountingRepositoryService) { 
  this.CurrentUser = new Employee();
     this.AvailableReports = AccountingRepositoryService.AvailableReports;
 }

 ngOnInit() {
   let subscription = this._accountingRepository.CurrentUser.subscribe(
     value => this.CurrentUser = value
   )
 

    

 }

 logout() {
  this._accountingRepository.logout();
 }

}
