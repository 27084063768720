import { EntityBase } from './entity-base';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Employee extends EntityBase {

    /// <code> Place custom code between <code> tags
    /// [Initializer]
    static initializer(entity: Employee) { }
    /// </code>

    // Generated code. Do not place code below this line.
    id: number;
    firstName: string;
    lastName: string;
    domainLogin: string;
    emailAddress: string;
    cellPhoneNumber: string;
    amexImportId: number;
    amexLastName: string;
    amexMiddleName: string;
    amexFirstName: string;
    amexAccountNumber: string;
    administrator: boolean;
    amexProcessor: boolean;
    gLClassifier: boolean;
    corporateOverview: boolean;
    defaultGLBusinessSegment: string;
    defaultGLDescription: string;
}

