import { EntityBase } from './entity-base';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ExpenseReason extends EntityBase {

    /// <code> Place custom code between <code> tags
    /// [Initializer]
    static initializer(entity: ExpenseReason) { }
    /// </code>

    // Generated code. Do not place code below this line.
    id: number;
    active: boolean;
    reason: string;
}

