import { Component, OnInit } from '@angular/core';
import { AccountingRepositoryService } from "app/services/accountingrepository.service";
import { Employee, ExpenseHeader } from "app/model/entity-model";
import { ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
import { CanComponentDeactivate } from 'app/services/can-deactivate-guard';

@Component({
  selector: 'app-employee-expense-report-list',
  templateUrl: './employee-expense-report-list.component.html',
  styleUrls: ['./employee-expense-report-list.component.css']
})
export class EmployeeExpenseReportListComponent implements OnInit, CanComponentDeactivate {
  private sub: any;
  private employeeId: number;
  private employee: Employee = new Employee;
  private expenseHeaders: ExpenseHeader[];
  private errorMessage: string;
  
  constructor(private _accountingRepository: AccountingRepositoryService, private route: ActivatedRoute, private _location: Location) { }

  ngOnInit() {
     this.sub = this.route.params.subscribe(params => { this.employeeId = +params['employeeId'];})
     
    this._accountingRepository.getEmployee(this.employeeId).then(employee => {
       this.employee = employee;
      },error => { 
       console.log(error.message)});

       this._accountingRepository.getExpenseHeadersForEmployee(this.employeeId).then(expenseheaders => {
         this.expenseHeaders = expenseheaders;         
       },error => {console.log(error.message)});
      
  }

 back() {
    this._location.back();
  }

 canDeactivate() : boolean {
   
    return true;
 }
}
