import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { AccountingRepositoryService } from "app/services/accountingrepository.service";

@Component({
  selector: 'app-amex-send-email',
  templateUrl: './amex-send-email.component.html',
  styleUrls: ['./amex-send-email.component.css']
})

 

export class AmexSendEmailComponent implements OnInit {
 
 

  constructor(private _accountingRepository: AccountingRepositoryService, private route: ActivatedRoute) {
    this.sub = this.route.params.subscribe(params => { this.amexImportId = +params['amexImportId'] });
   }
  
  private sub : any;
  private amexImportId: number;
  private subject : string;
  private message : string;
  private emailTemplates : EmailTemplate[]= [];
  private selectedTemplate : EmailTemplate;

  ngOnInit() {
      
      this.emailTemplates.push(new EmailTemplate("New Report Email", "Please Complete Your [REPORTNAME]", "Hello [EMPLOYEENAME],\n\nYour American Express Report for this past month ([REPORTNAME]) has been loaded in to the expense system.  Please complete it as soon as possible.\n\nIt is due by [DUEDATE].\n\nYou can access the report by clicking [LINKSTART]here[LINKEND]\n\nThank you,\n\nWTS Finance Group"));
      this.emailTemplates.push(new EmailTemplate("Followup Reminder ","Reminder: Please Complete Your [REPORTNAME]","Hello [EMPLOYEENAME],\n\nPlease remember to complete your [REPORTNAME].\n\nIt is due by [DUEDATE].\n\nIf you believe you already completed it but still received this email, please make sure you hit the submit report button.\n\n\You can access the report by clicking [LINKSTART]here[LINKEND]\n\nThank you,\n\nWTS Finance Group"));
      this.emailTemplates.push(new EmailTemplate("Delinquent Reminder ","Your [REPORTNAME] is Now Past Due","Hello [EMPLOYEENAME],\n\nYour [REPORTNAME] is past due.\n\nIt was due by [DUEDATE].\n\nPlease complete it A.S.A.P.\n\nIf you believe you already completed it but still received this email, please make sure you hit the submit report button.\n\n\You can access the report by clicking [LINKSTART]here[LINKEND]\n\nThank you,\n\nWTS Finance Group"));
      this.subject = "";
      this.message = "";

  }

  SendEmail() {

      if (this.subject == "" || this.message == "")
      {
        confirm("You must have both a message and body before an email can be sent");
      }
      else
      {
        if (confirm("Are you sure you want to send this email?")) {
          console.log('Clicked ' + this.amexImportId);
          this._accountingRepository.sendAmexReminderEmails(this.amexImportId, this.subject, this.message);
          confirm("Emails Sent");
        }
      }
      
    }

    TemplateSelected() {
      this.subject = this.selectedTemplate.subject;
      this.message = this.selectedTemplate.message;
       
    }



}


export class EmailTemplate {
     public name: string;
     public subject: string;
     public message: string;
     constructor(name: string, subject: string, message: string) {
       this.name = name;
       this.subject = subject;
       this.message = message;
     }   
 }
