import { Component, OnInit } from '@angular/core';
import { ExpenseHeader, ExpenseDetail, ExpenseReason, Employee, ExpenseProject, Prospect } from 'app/model/entity-model';
import { AccountingRepositoryService } from 'app/services/accountingrepository.service';
import { ActivatedRoute, Router, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Location } from '@angular/common';
import { CanComponentDeactivate } from 'app/services/can-deactivate-guard';

@Component({
  selector: 'app-expense-report',
  templateUrl: './expense-report.component.html',
  styleUrls: ['./expense-report.component.css']
})
export class ExpenseReportComponent implements OnInit, CanComponentDeactivate {

  private expenseheader: ExpenseHeader = new ExpenseHeader();
  private expensedetails: ExpenseDetail[];
  private sub: any;
  private expenseHeaderId: number;
  private ShowDetail = false;
  public LookupExpenseGLBusinessSegments: string[];
  public LookupExpenseGLDescriptions: string[];
  public LookupExpenseProjects: ExpenseProject[];
  public LookupExpenseReasons: ExpenseReason[];
  private CurrentExpenseDetailRecord: ExpenseDetail;
 // private customerSearch :  string;
  private prospectSearch : string;
  private ACCPACCustomers : any[];
  private ACCPACOrders : any[];
  private Prospects : Prospect[];
  private NewProspect: Prospect;
  private LastExpenseDetailRecord: ExpenseDetail = null;
  private RequestedNavigateToUrl: string;

  constructor(private _accountingRepository: AccountingRepositoryService, private route: ActivatedRoute, private router: Router, private _location: Location) {

    this.expenseheader.employee = new Employee();
    this.NewProspect = new Prospect();
   }

  ngOnInit() {
     this.sub = this.route.params.subscribe(params => { this.expenseHeaderId = +params['expenseHeaderId'];})

     // set lookup values
      this.LookupExpenseGLBusinessSegments = this._accountingRepository.LookupExpenseGLBusinessSegments;
      this.LookupExpenseGLDescriptions = this._accountingRepository.LookupExpenseGLDescription;
      this.LookupExpenseProjects = this._accountingRepository.LookupExpenseProjects;
      this.LookupExpenseReasons = this._accountingRepository.LookupExpenseReasons;

      this._accountingRepository.getExpenseHeaderWithEmployeeAndExpenseDetails(this.expenseHeaderId).then(expenseHeader => {
       this.expenseheader = expenseHeader;
        this._accountingRepository.getExpenseDetails(this.expenseHeaderId).then(expenseDetail => {
            this.expensedetails = expenseDetail;
        }, error => {
          console.log(error.message)
        });



      },error => { 
       console.log(error.message)
      });
          


  }

  canDeactivate(nextUrl: string) : boolean {
    this.RequestedNavigateToUrl = nextUrl;
    if (this._accountingRepository.hasChanges()) {
        $('#modal-ConfirmExit').modal("show");
        return false;
    }
    else
    {
    return true;
    }
        
  }

  EmployeeSubmitReport() {
      let GoodToSubmit : boolean = true;

      this.expensedetails.forEach(function (detail) {
          if (detail.gLBusinessSegment == null && detail.gLDescription == null || detail.customerBillable == 0 || detail.projectId == null || ((detail.customerBillable == 2 && detail.customerName == null) || (detail.customerBillable == 3 && detail.customerName == null) || (detail.customerBillable == 4 && detail.customerName == null)))
          {
            GoodToSubmit = false;
          }
      });
      if (GoodToSubmit ==  true)
      {

        this._accountingRepository.saveChanges().then(_ => {
          this._accountingRepository.submitEmployeeExpenseReport(this.expenseheader.id).then(_ => {
                  alert("The report has been submitted Successfully!");
                  this._location.back();
                }, function(error)
                {
                    alert("Unable to submit report an error has occured")
                });
        }, function(error) 
        {
          alert("Error: Unable to save your report")
        });



      }
      else
      {
        alert("You have transactions that have not been completed.  You can not submit this report yet");
      }
  }

  CancelChangesAndProceed() {
    this._accountingRepository.cancelChanges();
    this.router.navigateByUrl(this.RequestedNavigateToUrl);
  }

  customerBillableChange(expensedetail : ExpenseDetail ) {
    // customerbillable
    // 0 Specify Reason
    // 1 Internal Charge
    // 2 Billable Customer Activity
    // 3 NonBillable Customer Activity
    // 4 Busines Development
    if (expensedetail.customerBillable == 0 || expensedetail.customerBillable == 1 || expensedetail.customerBillable == 4)
    {
        if (expensedetail.customerCode != null) expensedetail.customerCode = null;
        if (expensedetail.customerName != null) expensedetail.customerName = null;       
    }
    expensedetail.customerOrderNumber = null;
    this.SaveLastRecord(expensedetail);

  }

  SaveLastRecord(currentExpenseDetail : ExpenseDetail)
  {
    if (this.LastExpenseDetailRecord != currentExpenseDetail)
    {
      this.LastExpenseDetailRecord = currentExpenseDetail;
    }
  }
  onEnterCustomerSearch(expensedetail: ExpenseDetail) {
    this.CustomerSearch(expensedetail);
  }
  CustomerSearch(expensedetail: ExpenseDetail) {
    this.CurrentExpenseDetailRecord = expensedetail;
    //let keywordSearch = this.customerSearch;
    let keywordSearch = expensedetail.customerSearch;
    expensedetail.customerSearch = "";
    //this.customerSearch = "";
    expensedetail.customerCode = null;
    expensedetail.customerName = null;
    expensedetail.customerOrderNumber = null;
    if ((keywordSearch == null ? '' :keywordSearch).trim() != "") {

      if (expensedetail.customerBillable == 4) // they are a prospect
      {
        this._accountingRepository.getProspectByKeyword(keywordSearch).then(p => {
            this.prospectSearch = keywordSearch;
            this.Prospects = p;
            $('#modal-ProspectSelect').modal("show");
        });          


      }
      else
      {
            this._accountingRepository.getAccpacCustomers(keywordSearch).then(c => {
              if (c.length == 0)
              {
                alert("Customer Not Found");
              }
              else
              {
                if (c.length == 1)
                { 
                    expensedetail.customerCode = c[0].accpacCode;
                    expensedetail.customerName = c[0].name;
                }
                else
                {
                    this.ACCPACCustomers = c;           
                    $('#modal-ACCPACCustomerSelect').modal("show");

                }
              }
            });
      }
    }
    else
    {
      alert("You must enter a keyword to search on");
    }
}

onEnterCustonerOrderNumberSearch(expensedetail: ExpenseDetail) {
    this.CustomerOrderNumberSearch(expensedetail);
  }

CustomerOrderNumberSearch(expensedetail : ExpenseDetail)
{    
  
  if (expensedetail.customerCode != null && expensedetail.customerCode != "PROS")
  {
    this.CurrentExpenseDetailRecord = expensedetail;
    this._accountingRepository.getAccpacCustomerOrders(expensedetail.customerCode).then(o => {
              if (o.length == 0)
              {
                alert("No Open Orders Found");
              }
              else
              {
                if (o.length == 1)
                { 
                    expensedetail.customerOrderNumber = o[0].orderNumber;                    
                }
                else
                {
                    this.ACCPACOrders = o;      
                    $('#modal-ACCPACCustomerOrderSelect').modal("show");

                }
              }
            });

  }
  else
  {
    alert("You must select a valid customer first");
  }

}
  CustomerSelected(ACCPACCustomerSelected) 
  {
    this.CurrentExpenseDetailRecord.customerCode = ACCPACCustomerSelected.accpacCode;
    this.CurrentExpenseDetailRecord.customerName = ACCPACCustomerSelected.name;
    $('#modal-ACCPACCustomerSelect').modal("hide");
    this.CurrentExpenseDetailRecord.customerOrderNumber = null;
    this.SaveLastRecord(this.CurrentExpenseDetailRecord);
   // ACCPACCustomerSelected = null;
  }

 CustomerOrderSelected(ACCPACOrderSelected)
  {
    this.CurrentExpenseDetailRecord.customerOrderNumber = ACCPACOrderSelected.orderNumber;
    this.SaveLastRecord(this.CurrentExpenseDetailRecord);
    $('#modal-ACCPACCustomerOrderSelect').modal("hide");
  }
  ProspectSelected(ProspectSelected :  Prospect)
  {
    this.CurrentExpenseDetailRecord.customerCode = "PROSP";
    this.CurrentExpenseDetailRecord.customerName = ProspectSelected.prospectName;
    this.SaveLastRecord(this.CurrentExpenseDetailRecord);
     $('#modal-ProspectSelect').modal("hide");

  }

  AddProspect()
  {
    $("#AddNewProspectButton").hide();
    this.NewProspect = <Prospect>this._accountingRepository.createEntity("Prospect");
    $("#AddNewProspectFields").show();
    $("#ExistingProspects").hide();
    $("#myProspectModalLabel").html("Please Add Your New Prospect");
  }

  ReuseLastInformation(currentExpenseDetail: ExpenseDetail)
  {
    if (this.LastExpenseDetailRecord != null)
    {
    currentExpenseDetail.gLBusinessSegment = this.LastExpenseDetailRecord.gLBusinessSegment;
    currentExpenseDetail.gLDescription = this.LastExpenseDetailRecord.gLDescription;
    currentExpenseDetail.customerBillable = this.LastExpenseDetailRecord.customerBillable;
    currentExpenseDetail.customerCode = this.LastExpenseDetailRecord.customerCode;
    currentExpenseDetail.customerName = this.LastExpenseDetailRecord.customerName;    
    currentExpenseDetail.projectId = this.LastExpenseDetailRecord.projectId;
    currentExpenseDetail.customerOrderNumber = this.LastExpenseDetailRecord.customerOrderNumber;
    }
    else
    {
      alert("You must first complete an expense record before you can reuse it");
    }
  }

  SaveNewProspect()
  {
    this._accountingRepository.saveNewProspect(this.NewProspect);    
    this.CurrentExpenseDetailRecord.customerCode = "PROSP";
    this.CurrentExpenseDetailRecord.customerName = this.NewProspect.prospectName;   
    $("#AddNewProspectFields").hide();
    $("#AddNewProspectButton").show();
    $("#ExistingProspects").show();
    $("#myProspectModalLabel").html("Please Select The Prospect");
    $('#modal-ProspectSelect').modal("hide");
    this.LastExpenseDetailRecord = this.CurrentExpenseDetailRecord;
  }
  saveChanges() {
    let WasPreviouslySubmitted : boolean  = false;
    if (this.expenseheader.submittedById != null)
    {
        WasPreviouslySubmitted = true;
        this.expenseheader.submittedById = null;
        this.expenseheader.submittedDate = null;
        this.expenseheader.employeeReviewComplete = false;
    }
    this._accountingRepository.saveChanges().then(_ => {
      if (WasPreviouslySubmitted)
      { 
        alert("Expense Report was saved.  However it was previously submitted.  So you MUST RE-SUBMIT IT again once you are finished");
      }
      else
      {
        alert("Expense Report Saved");
      }
    }, function(error) 
    {
      alert("Issue saving: " + error.message)
    });
  }


  back() {
    this._location.back();
  }
}
