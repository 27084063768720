import { EntityBase } from './entity-base';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class AmexAutoClassify extends EntityBase {

    /// <code> Place custom code between <code> tags
    /// [Initializer]
    static initializer(entity: AmexAutoClassify) { }
    /// </code>

    // Generated code. Do not place code below this line.
    id: number;
    keyword: string;
    keywordPosition: string;
    businessSegment: string;
    gLDescription: string;
}

