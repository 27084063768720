import { Component, OnInit } from '@angular/core';
import { ExpenseHeader,Employee } from 'app/model/entity-model';
import { AccountingRepositoryService } from 'app/services/accountingrepository.service';
import { ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
import { CanComponentDeactivate } from 'app/services/can-deactivate-guard';

@Component({
  selector: 'app-amex-statement-employee-list',
  templateUrl: './amex-statement-employee-list.component.html',
  styleUrls: ['./amex-statement-employee-list.component.css']
})
export class AmexStatementEmployeeListComponent implements OnInit, CanComponentDeactivate {
  private sub : any;
  private amexImportId: number;
  private Description : string;
  private expenseheaders: ExpenseHeader[];
  private errorMessage: string;
  constructor(private _accountingRepository: AccountingRepositoryService, private route: ActivatedRoute, private _location: Location) {
     this.sub = this.route.params.subscribe(params => { this.amexImportId = +params['amexImportId'] });

   }

  ngOnInit() {
    this._accountingRepository.getExpenseHeadersForEmployeeByAmexId(this.amexImportId).then(expenseheaders => {
          this.expenseheaders = expenseheaders;
          if (expenseheaders.length > 0)
          {
            this.Description = expenseheaders[0].description;
          }
      },error => { 
        console.log(error.message);
      });
      
      AccountingRepositoryService.AvailableReports.push({ Name: 'Monthly AMEX Detail Report', Link: 'https://portal.wtsonline.com/breeze/reporting/MonthlyAmexReportDetail?AmexImportId=' + this.amexImportId + '&Token=' + AccountingRepositoryService.CurrentToken});
      AccountingRepositoryService.AvailableReports.push({ Name: 'Monthly AMEX GL Summary', Link: 'https://portal.wtsonline.com/breeze/reporting/MonthlyAmexReportGLSummary?AmexImportId=' + this.amexImportId + '&Token=' + AccountingRepositoryService.CurrentToken});
      console.log(AccountingRepositoryService.AvailableReports);
  }


  back() {
    this._location.back();
  }

 

  canDeactivate() : boolean {
    console.log("going to clear reports"); 
    AccountingRepositoryService.AvailableReports.splice(0,AccountingRepositoryService.AvailableReports.length);
    console.log("reports cleared");
    return true;
  }
}
