import { EntityBase } from './entity-base';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class TokenAuthorization extends EntityBase {

    /// <code> Place custom code between <code> tags
    
    /// </code>

    // Generated code. Do not place code below this line.
    id: number;
    tokenId: string;
    createdDate: Date;
    expireDate: Date;
    lastIPAddress: string;
    userName: string;
}

