import { EntityBase } from './entity-base';
import { ExpenseHeader } from './expense-header';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class AmexImport extends EntityBase {

    /// <code> Place custom code between <code> tags
    /// [Initializer]
    static initializer(entity: AmexImport) { }
    /// </code>

    // Generated code. Do not place code below this line.
    id: number;
    importFileName: string;
    importStarted: Date;
    importFinished: Date;
    employeesCreated: number;
    transactionsImported: number;
    total: number;
    notes: string;
    expenseHeaders: ExpenseHeader[];
}

