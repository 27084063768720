import { EntityBase } from './entity-base';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Prospect extends EntityBase {

    /// <code> Place custom code between <code> tags
    /// [Initializer]
    static initializer(entity: Prospect) { }
    /// </code>

    // Generated code. Do not place code below this line.
    id: number;
    prospectName: string;
    address: string;
    city: string;
    state: string;
    zipcode: string;
    siteContact: string;
}

