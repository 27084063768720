import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

//import { BreezeBridgeAngularModule } from '../../node_modules/breeze-bridge-angular';
import { BreezeBridgeAngularModule } from 'breeze-bridge-angular';
import { NamingConvention } from 'breeze-client';


import { AccountingRepositoryService } from './services/accountingrepository.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { InitGuard } from './services/init-guard';
import { FormatDatePipe } from './services/FormatDatePipe';
import { AutoFocusDirective } from './services/myAutoFocus.directive';

import { EmployeeListComponent } from './employee-list/employee-list.component';
import { EmployeeExpenseReportListComponent } from './employee-expense-report-list/employee-expense-report-list.component';
import { ExpenseReportComponent } from './expense-report/expense-report.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from "app/services/auth-guard";
import { CanDeactivateGuard } from 'app/services/can-deactivate-guard';
import { AmexStatementListComponent } from './amex-statement-list/amex-statement-list.component';
import { AmexStatementEmployeeListComponent } from './amex-statement-employee-list/amex-statement-employee-list.component';
import { AmexSendEmailComponent } from './amex-send-email/amex-send-email.component';



@NgModule({
  declarations: [
    AppComponent,
    EmployeeListComponent,
    EmployeeExpenseReportListComponent,
    ExpenseReportComponent,
    FormatDatePipe,
    LoginComponent,
    AutoFocusDirective,
    AmexStatementListComponent,
    AmexStatementEmployeeListComponent,
    AmexSendEmailComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    BreezeBridgeAngularModule,
    AppRoutingModule,
  
 
  ],
  providers: [
    AccountingRepositoryService, 
    InitGuard,
    AuthGuard,
  //  CheckForMetadataGuard,
    CanDeactivateGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor() {
    // if we want to change breeze naming convention
    NamingConvention.camelCase.setAsDefault(); 

  }

}
