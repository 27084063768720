import { Component, OnInit } from '@angular/core';
import { Employee } from 'app/model/entity-model';
import { AccountingRepositoryService } from 'app/services/accountingrepository.service';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css'],
})
export class EmployeeListComponent implements OnInit {

  private employees: Employee[];
  private errorMessage: string;

  constructor(private _accountingRepository: AccountingRepositoryService) { }

  ngOnInit() {
     this._accountingRepository.getEmployees().then(employees => {
      this.employees = employees;
   },error => { 
     console.log(error.message);})
  }

 }
