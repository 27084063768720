import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';

import { AccountingRepositoryService } from './accountingrepository.service';

@Injectable()
export class InitGuard implements CanActivate, CanActivateChild {

    constructor(private _accountingRepository: AccountingRepositoryService) {}

    canActivate() : Promise<boolean> {   
        return  this._accountingRepository.initialize();        
    }

    canActivateChild() : Promise<boolean> {        
        return this.canActivate();
    }


 
}