import { EntityBase } from './entity-base';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ExpenseDetail extends EntityBase {

    /// <code> Place custom code between <code> tags
    /// [Initializer]
    static initializer(entity: ExpenseDetail) { }
    customerSearch: string;
    /// </code>

    // Generated code. Do not place code below this line.
    id: number;
    expenseHeaderId: number;
    amexImportId: number;
    amexLineNumber: number;
    rejected: boolean;
    transactionDate: Date;
    referenceNumber: string;
    amount: number;
    merchant: string;
    transactionDetail: string;
    employeeDetail: string;
    rejectionDetail: string;
    customerBillable: number;
    customerOrderNumber: string;
    customerCode: string;
    customerName: string;
    projectId: number;
    fraud: boolean;
    gLBusinessSegment: string;
    gLDescription: string;
}

